<template>
  <v-app>

      <router-view :key="$route.fullPath" />


  </v-app>
</template>

<script>


import('@/assets/css/w3.css')
import('@/assets/css/animate.css')
export default {
  name: 'App',

  data: () => ({
    //
  }),

};
</script>
<style>
.notransform{
  text-transform: none!important;
}
</style>
