import Vue from 'vue'
import VueRouter from 'vue-router'
import {userRoute} from "@/router/userRoute";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    meta:{
      title:'Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/card-reservation/:id',
    name: 'cardReservation',
    component: () => import(/* webpackChunkName: "card-reservation" */ '../views/cardReservation.vue'),
    meta:{
      title:'Card Reservation - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/store-reservation/:id',
    name: 'storeReservation',
    component: () => import(/* webpackChunkName: "store-reservation" */ '../views/storeReservation.vue'),
    meta:{
      title:'Store Reservation - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta:{
      title:'Login - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue'),
    meta:{
      title:'Creat an Account - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/about-us',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    meta:{
      title:'About Us- Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/terms.vue'),
    meta:{
      title:'Terms and Conditions - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy.vue'),
    meta:{
      title:'Privacy Policy - Pebble | A Virtual Credit Card & Store'
    }
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue'),
    meta:{
      title:'Contact Us - Pebble | A Virtual Credit Card & Store'
    }
  },

    //user routes
    ...userRoute,







    //page not found
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/error.vue'),
    meta:{
      title:'Page not Found - Pebble | A Virtual Credit Card & Store'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
