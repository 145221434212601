import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Cookies from "js-cookie";

export const bus = new Vue()
export const api = `https://ckpebb.pebble.ng/docsapi/`
Vue.config.productionTip = false

//verify access on routing to each page
router.beforeEach((to, from, next) => {
  if (to.meta.auth === 'user' && Cookies.get('user-logged')===undefined) next({ name: 'login' });
  else next()
})

router.beforeEach((toRoute, fromRoute, next) => {
  next()
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Pebble | A Virtual Credit Card & Store';
  window.scroll({behavior:'smooth'})
  window.scroll(0,0)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
