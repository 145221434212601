import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {userStore} from "@/store/userStore";
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    userStore
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
