
import {userForm} from "@/hooks/userForm";
import {userFetch} from "@/hooks/userFetch";
import router from "@/router";
import logUserOut from "@/hooks/logout";

export const userStore = {
    state:{
        user:'',
        interests:"",
        dataplan:'',
        userinfo:'',
        transactionHistory:'',
        pebblesocial:'',
        transactionDetails:'',
        cart:[],
        products:'',
        product:'',
        orders:'',
        notifications:'',
        productReview:'',
        pickup:'',
        postDetails:'',
        broadcastMessages:'',
        broadcastMessageDetails:'',
        storelist:'',
        productCategories:'',
        cablePackages:'',
        state:'',
        lga:'',
        userinterest:'',
        banks:'',
        myinvites:'',
        ivcode:'',
        bettingBiller:'',
        electBiller:'',
        electCategory:'',
        searchProd:'',
    },
    getters:{

        getUser:state=>state.user,
        getInterests:state=>state.interests,
        getDataPlans: state=>state.dataplan,
        getUserDetails: state=>state.userinfo,
        getPebbleSocial:state=>state.pebblesocial,
        getTransactionHistory: state=>state.transactionHistory,
        getTransactionDetails: state=>state.transactionDetails,
        getCart: state=>state.cart,
        getProducts:state=>state.products,
        getProduct:state=>state.product,
        getOrder:state=>state.orders,
        getNotifications:state=>state.notifications,
        getProductReview:state=>state.productReview,
        getPostDetails:state=>state.postDetails,
        getBroadcastMessages:state=>state.broadcastMessages,
        getBroadcastMessageDetails:state=>state.broadcastMessageDetails,
        getStoreList: state=>state.storelist,
        getProductCategories: state=>state.productCategories,
        getCablePackages: state=>state.cablePackages,
        getState:state=>state.state,
        getLga:state=>state.lga,
        getUserInterest:state=>state.userinterest,
        getBanks:state=>state.banks,
        getMyinvites:state=>state.myinvites,
        getIVcode: state=>state.ivcode,
        getBettingBiller: state=>state.bettingBiller,
        getElectBiller: state=>state.electBiller,
        getElectCategory: state=>state.electCategory,
        getSearchProd: state=>state.searchedProd

    },
    mutations:{
        updateUser:(state,data)=>state.user=data,
        updateInterest:(state,data)=>state.interests=data,
        updateDataPlans:(state,data)=>state.dataplan=data,
        updateUserDetails:(state,data)=>state.userinfo=data,
        updateTransactionHistory:(state,data)=>state.transactionHistory=data,
        updateTransactionDetails:(state,data)=>state.transactionDetails=data,
        updatePebbleSocial: (state,data)=>state.pebblesocial=data,
        resetCart:(state,data)=>state.cart=data,
        addToCart:(state,data)=>{
            // return state.cart=[]

            let find = state.cart.find(item => item.id === data.id)
            let index = state.cart.indexOf(find)
            if(find===undefined){
                return state.cart.push(data)
            }

            return state.cart[index].quantity+=1

        },
        removeFromCart:(state,data)=>{
            let find = state.cart.find(item => item.id === data.id)
            let index = state.cart.indexOf(find)
            if(index!== -1){
                return state.cart.splice(index, 1)
            }
            return false
        },
        adjustCartQuantity:(state,data)=>{
            let item = state.cart[data.index]
            if(data.mode==='reduce'){
                if(item.quantity<=1) return
                return state.cart[data.index].quantity-=1
            }
            return state.cart[data.index].quantity+=1

        },
        updateProducts:(state,data) => state.products = data,
        updateProduct:(state,data)=>state.product=data,
        updateOrders:(state,data)=>state.orders=data,
        updateNotifications:(state,data)=>state.notifications=data,
        updateProductReview:(state,data)=>state.productReview=data,
        updatePostDetails:(state,data)=>state.postDetails=data,
        updateBroadcastMessages:(state,data)=>state.broadcastMessages=data,
        updateBroadcastMessageDetails:(state,data)=>state.broadcastMessageDetails=data,
        updateStorelist:(state,data)=>state.storelist=data,
        updateProductCategories:(state,data)=>state.productCategories=data,
        updateCabletvPackages:(state,data)=>state.cablePackages=data,
        updateState:(state,data)=>state.state=data,
        updateLga:(state,data)=>state.lga=data,
        updateUserInterest:(state,data)=>state.userinterest=data,
        updateBanks:(state,data)=>state.banks=data,
        updateMyInvites:(state,data)=>state.myinvites=data,
        updateIVcode:(state,data)=>state.ivcode=data,
        updateBettingBiller: (state,data)=>state.bettingBiller=data,
        updateElecBiller: (state,data)=>state.electBiller=data,
        updateElectCategory: (state,data)=>state.electCategory=data,
        updateSearchProd:(state,data)=>state.searchedProd=data


    },
    actions:{

        reserveCard : async (context, data)=>{
            try{
                return await userFetch('reservecard', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        reserveStore : async (context, data)=>{
            try{
                return await userFetch('reservestore', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        signIn:  async (context, data)=>{
            try{
                return await userFetch('signin', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        verifyLogin:  async (context, data)=>{
            try{
                return await userFetch('verifylogin', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        createAccount:  async (context, data)=>{
            try{
                return await userFetch('createaccount', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        createCard:  async (context, data)=>{
            try{
                return await userFetch('createcard', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        createPin:  async (context, data)=>{
            try{
                return await userFetch('createpin', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        verifyRegOTP:async (context, data)=>{
            try{
                return await userFetch('verifyreserve', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        addInterest:  async (context, data)=>{
            try{
                return await userFetch('addinterest', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchInterest:  async (context, data)=>{
            try{
                await window.fetch(`https://ckpebb.pebble.ng/docsapi/interests`,{
                    method:'GET'
                })
                    .then(response=>response.json())
                    .then(response=>{
                        context.commit('updateInterest', response)
                    })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchDataPlans: async (context,data)=>{
            try{
                 await userFetch('dataplans', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                        network:data.network
                    }
                ))
                     .then(response=>response.json())
                     .then(response=>{
                         if(response.status)return context.commit('updateDataPlans',response.data)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchUserDetails: async (context)=>{
            try{
                 await userFetch('userinfo', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid
                    }
                ))
                     .then(response=>response.json())
                     .then(async response=>{
                         if(response?.isdisabled){
                             await window.alert("A new user session detected")
                             await logUserOut()
                             return router.push(
                                 {name: 'login'},
                                 () => {
                                     window.location.reload()
                                 }
                             )
                         }
                         return context.commit('updateUserDetails',response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchDataPrice: async (context,data)=>{
            try{
                 await userFetch('dataprices', userForm(
                    {
                        scode: context.getters.user.scode,
                        userid:context.getters.user.userid,
                        datacode:data.datacode,
                    }
                ))
                     .then(response=>response.json())
                     .then(response=>{
                         if(response.status)return context.commit('updateUserDetails',response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchTransaction: async (context)=>{
            try{
                 await userFetch('transhistory', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                    }
                ))
                     .then(response=>response.json())
                     .then(response=>{
                          return context.commit('updateTransactionHistory',response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchTransactionDetails: async (context,data)=>{
            try{
                 await userFetch('transdetails', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                        txref:data.txref,
                    }
                ))
                     .then(response=>response.json())
                     .then(response=>{
                          return context.commit('updateTransactionDetails',response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchSocial: async (context)=>{
            try{
                 await userFetch('allposts', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                    }
                ))
                     .then(response=>response.json())
                     .then(response=>{
                          return context.commit('updatePebbleSocial',response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        purchaseAirtime: async (context, data)=>{
            try{
                return await userFetch('airtime', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        purchaseData: async (context, data)=>{
            try{
                return await userFetch('databundle', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        followStore: async (context, data)=>{
            try{
                return await userFetch('followstore', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchProducts: async (context)=>{
            try{
                await userFetch('storeproducts', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                    }
                ))
                    .then(response=>response.json())
                    .then(response=>{
                        return context.commit('updateProducts',response)
                    })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        fetchOrders: async (context)=>{
            try{
                await userFetch('myorders', userForm(
                    {
                        scode: context.getters.getUser.scode,
                        userid:context.getters.getUser.userid,
                    }
                ))
                    .then(response=>response.json())
                    .then(response=>{
                        return context.commit('updateOrders',response)
                    })
            }
            catch (e) {
                console.log(e.message)

            }

        },
        checkOut: async (context, data)=>{
            try{
                return await userFetch('paycheckout', userForm(data))
            }
            catch (e) {
                console.log(e.message)

            }

        },
        claimNewCash :async (context,data)=>{
            try{
                return await userFetch('claimcash', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        getNewOffer :async (context,data)=>{
            try{
                return await userFetch('getnewoffer', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        changePin :async (context,data)=>{
            try{
                return await userFetch('managetpin', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        manageCard :async (context,data)=>{
            try{
                return await userFetch('managecard', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchNotifications:async (context,data)=>{
            try{
                 await userFetch('notifications', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
                     .then(response=>response.json())
                     .then(response=>context.commit('updateNotifications',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchProductReview:async (context,data)=>{
            try{
                 await userFetch('view_productreview', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                     ...data
                }))
                     .then(response=>response.json())
                     .then(response=>context.commit('updateProductReview',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        addNewReview :async (context,data)=>{
            try{
                return await userFetch('product-review', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchPostDetails :async (context,data)=>{
            try{
                 await userFetch('viewcomments', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                })).then(response=>response.json())
                     .then(response=> context.commit('updatePostDetails',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        addComment :async (context,data)=>{
            try{
                return await userFetch('postcomment', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        togglePostLike :async (context,data)=>{
            try{
                return await userFetch('postlikes', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        changeProfile :async (context,data)=>{
            try{
                return await userFetch('updateinfo', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        uploadAvatar :async (context,data)=>{
            try{
                return await userFetch('profileimg', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchBroadcastMessages :async (context,data)=>{
            try{
                await userFetch('broadcastmessage', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateBroadcastMessages',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchBroadcastMessageDetails :async (context,data)=>{
            try{
                await userFetch('showbroadcast', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                })).then(response=>response.json())
                    .then(response=> context.commit('updateBroadcastMessageDetails',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        deleteMessage :async (context,data)=>{
            try{
                return await userFetch('deletebroadcast', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        deleteNotifications :async (context,data)=>{
            try{
                return await userFetch('removenotify', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        readNotifications :async (context,data)=>{
            try{
                return await userFetch('readnotifications', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,

                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchStoreList :async (context)=>{
            try{
                await userFetch('storelist', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateStorelist',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchProductCategories :async (context)=>{
            try{
                await userFetch('productcatgry', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateProductCategories',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchCablePackages :async (context,data)=>{
            try{
                await userFetch('cabletv-type', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateCabletvPackages',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        verifyIUC :async (context,data)=>{
            try{
                return await userFetch('verifyiuc', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        payCable :async (context,data)=>{
            try{
                return await userFetch('vendcable', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchStates :async (context,)=>{
            try{
                await window.fetch('https://ckpebb.pebble.ng/docsapi/list-states',
                    {
                        method:'get'
                    }
                    )
                    .then(response=>response.json())
                    .then(response=> context.commit('updateState',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchLGA :async (context,data)=>{
            try{
                await userFetch('getlgvt', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateLga',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchUserInterest :async (context)=>{
            try{
                await userFetch('myinterest', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateUserInterest',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        verifyBVN:async (context,data)=>{
            try{
                return await userFetch('verbvn', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        verifyBVNOTP: async (context,data)=>{
            try{
                return await userFetch('verifyotpp', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        changeUserAddress :async (context,data)=>{
            try{
                return await userFetch('updaddress', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        submitFeedback :async (context,data)=>{
            try{
                return await userFetch('purchasefeedback', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...data
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchBanks :async (context)=>{
            try{
                await userFetch('dedicatedbank', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateBanks',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        generateAccount: async (context)=>{
            try{
                return await userFetch('create-accountno', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchMyInvites :async (context)=>{
            try{
                await userFetch('myinvite', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                })).then(response=>response.json())
                    .then(response=> context.commit('updateMyInvites',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },

        resendBVNOtp: async (context)=>{
            try{
                return await userFetch('resendbvotp', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchIVcode: async (context)=>{
            try{
                await window.fetch('https://ckpebb.pebble.ng/docsapi/getivcode',
                    {
                        method:'get'
                    }
                )
                    .then(response=>response.json())
                    .then(response=> context.commit('updateIVcode',response))
            }
            catch (e) {
                console.log(e.message)

            }
        },

        fetchBettingBiller :async (context)=>{
            try{
                 await userFetch('bettingbiller', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
                     .then(response=>response.json())
                     .then(response=>{
                         if(response.status)return context.commit('updateBettingBiller', response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchElectBiller :async (context)=>{
            try{
                 await userFetch('electtype', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                }))
                     .then(response=>response.json())
                     .then(response=>{
                         if(response.status)return context.commit('updateElecBiller', response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }
        },
        fetchElectCategory :async (context,payload)=>{
            try{
                 await userFetch('electcatgry', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                     ...payload
                }))
                     .then(response=>response.json())
                     .then(response=>{
                         if(response.status)return context.commit('updateElectCategory', response)
                     })
            }
            catch (e) {
                console.log(e.message)

            }
        },
        verifyBettingAccount: async (context, payload)=>{
            try{
                return await userFetch('verifybetuser', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    billerIdentifier:payload.billerIdentifier,
                    customerID: payload.customerID,
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        verifyMeter: async (context, payload)=>{
            try{
                return await userFetch('verifyelect', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...payload
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        processBetting:async (context, payload)=>{
            try{
                return await userFetch('paybet', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...payload
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        processElec:async (context, payload)=>{
            try{
                return await userFetch('payelect', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...payload
                }))
            }
            catch (e) {
                console.log(e.message)

            }
        },
        searchProduct:async (context,payload)=>{
            try{
                await userFetch('searchstore', userForm(  {
                    scode: context.getters.getUser.scode,
                    userid:context.getters.getUser.userid,
                    ...payload
                }))
                    .then(response=>response.json())
                    .then(response=>{
                        if(response.status)return context.commit('updateProducts', response)
                    })
            }
            catch (e) {
                console.log(e.message)

            }
        },

        getBillPoint: async (context,payload)=>{
    try{
        return await userFetch('getbillpoint', userForm(  {
            scode: context.getters.getUser.scode,
            userid:context.getters.getUser.userid,
            ...payload
        }))
    }
    catch (e) {
        console.log(e.message)

    }
},


    }
}