
import {api} from '@/main'
export const userFetch = async (route, body)=>{
    return  await window.fetch(
        `${api}/${route}`,
        {
            method:'POST',
            body
        }
    )
}