
export const userRoute = [
    {
        path: '/user/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/user/dashboard.vue'),
        meta:{
            title:'Dashboard - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/card',
        name: 'card',
        component: () => import(/* webpackChunkName: "user-card" */ '../views/user/card.vue'),
        meta:{
            title:'Card - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/card/transaction-details/:reference',
        name: 'transactionDetails',
        component: () => import(/* webpackChunkName: "user-transaction-details" */ '../views/user/transactionDetails.vue'),
        meta:{
            title:'Transaction Details - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn',
        name: 'earn',
        component: () => import(/* webpackChunkName: "user-earn" */ '../views/user/earn.vue'),
        meta:{
            title:'Earn - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/paybills',
        name: 'paybill',
        component: () => import(/* webpackChunkName: "user-paybill" */ '../views/user/paybill.vue'),
        meta:{
            title:'Pay Bills - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/airtime-purchase',
        name: 'airtime',
        component: () => import(/* webpackChunkName: "user-airtime" */ '../views/user/airtime.vue'),
        meta:{
            title:'Airtime Purchase - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/data-purchase',
        name: 'data',
        component: () => import(/* webpackChunkName: "user-data" */ '../views/user/data.vue'),
        meta:{
            title:'Data Purchase - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/invite-friends',
        name: 'invite',
        component: () => import(/* webpackChunkName: "user-invite-friends" */ '../views/user/invite.vue'),
        meta:{
            title:'Invite Friends - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/product',
        name: 'productDesc',
        component: () => import(/* webpackChunkName: "user-product" */ '../views/user/productDesc.vue'),
        meta:{
            title:'Product  - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/checkout',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "user-product-checkout" */ '../views/user/checkout.vue'),
        meta:{
            title:'Check Out  - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/earn/cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "user-product-cart" */ '../views/user/cart.vue'),
        meta:{
            title:'Cart   - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/user/profile.vue'),
        meta:{
            title:'Profile - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile/profile-settings',
        name: 'profileSettings',
        component: () => import(/* webpackChunkName: "user-profile-settings" */ '../views/user/profileSettings.vue'),
        meta:{
            title:'Profile Settings - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile/edit-photo',
        name: 'editPhoto',
        component: () => import(/* webpackChunkName: "user-edit-photo" */ '../views/user/editPhoto.vue'),
        meta:{
            title:'Edit Profile Picture - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile/security-settings',
        name: 'securitySettings',
        component: () => import(/* webpackChunkName: "user-security-settings" */ '../views/user/securitySettings.vue'),
        meta:{
            title:'Security Settings - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile/pin',
        name: 'pin',
        component: () => import(/* webpackChunkName: "user-security-pin" */ '../views/user/pin.vue'),
        meta:{
            title:'PIN Management - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/profile/card-management',
        name: 'cardManagement',
        component: () => import(/* webpackChunkName: "user-security-card" */ '../views/user/cardManagement.vue'),
        meta:{
            title:'Card Management - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/card/add-funds',
        name: 'addFunds',
        component: () => import(/* webpackChunkName: "user-add-funds" */ '../views/user/addFunds.vue'),
        meta:{
            title:'Add Funds - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/success/:reference/:mode',
        name: 'success',
        component: () => import(/* webpackChunkName: "user-success" */ '../views/user/success.vue'),
        meta:{
            title:'Successful Payment - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/purchase/:reference',
        name: 'productSuccess',
        component: () => import(/* webpackChunkName: "product-success" */ '../views/user/productSuccess.vue'),
        meta:{
            title:'Successful Order - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/order-details/:id/:status',
        name: 'orderDetails',
        component: () => import(/* webpackChunkName: "order-details" */ '../views/user/orderDetails.vue'),
        meta:{
            title:'Order Details - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/messages',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '../views/user/message.vue'),
        meta:{
            title:'Notifications - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
    {
        path: '/user/messages/details/:id',
        name: 'messageDetails',
        component: () => import(/* webpackChunkName: "message-details" */ '../views/user/messageDetails.vue'),
        meta:{
            title:'Message Details - Pebble | A Virtual Credit Card & Store',
            auth:'user'
        }

    },
]