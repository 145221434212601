import SecureLS from "secure-ls";
import Cookies from 'js-cookie'

let ls = new SecureLS({ isCompression: false });


const logUserOut = async()=>{
     await ls.remove('vuex'); // remove all keys
    await window.sessionStorage.clear()
    await window.localStorage.removeItem('vuex')
    await window.localStorage.clear()
    await Cookies.remove('user-logged')


}
export default logUserOut